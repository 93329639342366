import React from 'react';
import NavBar from '../components/navbar/NavBar';

const Remerciements = () => {
  return (
    <div>
      <NavBar/>
      <div className="box_situation mx-auto px-4 py-8 bg-[#FFF] text-center shadow-lg rounded-lg">
        <div className='max-w-2xl mx-auto'>
        <h1 className="text-4xl font-bold mb-6 text-gray-700">Remerciements</h1>
        <p className="mb-6 text-lg text-gray-800">
          Nous tenons à remercier toutes les personnes qui ont contribué au développement de cette application, à savoir les apprenants Concepteur Développeur d'Application (CDA) du centre de formation Créative Tech School d'Hérouville Saint Clair :
        </p>
        <ul className="list-none space-y-2 mb-6">
          <li className="text-gray-900 font-semibold">Etienne HAUGUEL</li>
          <li className="text-gray-900 font-semibold">Hugo FORCADEL</li>
          <li className="text-gray-900 font-semibold">Jonathan FENOUD-VIARD</li>
          <li className="text-gray-900 font-semibold">Killian WAUTERS</li>
          <li className="text-gray-900 font-semibold">Luco ATCHAMA</li>
          <li className="text-gray-900 font-semibold">Maël BRIANTIN</li>
          <li className="text-gray-900 font-semibold">Paul BLANGUERNON</li>
          <li className="text-gray-900 font-semibold">Prisca CASTANIER</li>
          <li className="text-gray-900 font-semibold">Théo GAUDIN</li>
        </ul>
        <p>
          Ainsi que leur formateur <span className="text-gray-900 font-semibold">Pacôme Perrichot</span>, référent des CDA.
        </p>
        </div>
      </div>
    </div>
  );
};

export default Remerciements;